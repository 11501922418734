<template>
  <canvas id="tmpLabel" height="36" style="display: none;">Your browser does not support map labels!</canvas>
  <div class="map-container">
    <div
      v-if="!isSpinnerVisible"
      class="map-controls"
    >
      <div class="map-control">
        <div 
          :class="['form-control-toggle toggle-inline toggle-left toggle-warning']">
          <input 
            type="checkbox" 
            id="chkMapPinsList"
            :class="{'checked': !isAssetsListMapReflected}"
            :checked="!isAssetsListMapReflected"
            @change="setAssetsListMapReflected"
          >
          <label for="chkMapPinsList">
            <span>Show All Assets</span><span></span>
          </label>
        </div>
        <div style="padding-left: 5px">
          <InfoBadge
            placement="bottom"
            content="<div style='width: 250px'><strong>ON:</strong> Assets included in this map will change and show all Assets based on criteria defined in the filters.<br><br><strong>OFF:</strong> Assets included in this map will change based on Assets list.</div>"
          />
        </div>
      </div>
      <div class="map-control">
        <div 
          :class="['form-control-toggle toggle-inline toggle-left toggle-warning']">
          <input 
            type="checkbox" 
            id="chkMapPinsLabel"
            :class="{'checked': isLabelPinsOn}"
            :checked="isLabelPinsOn"
            @change="setLabelPins"
          >
          <label for="chkMapPinsLabel">
            <span>Labels</span><span></span>
          </label>
        </div>
      </div>
    </div>
    <div 
      ref="map"
      v-bind:class="['map', {'pins-are-visible': isMapReady}, {'spinner-is-visible': isSpinnerVisible}]"
    >
      <div class="spinner"></div>
    </div>
    <div
      v-if="isOverlayVisible"
      :class="['map-overlay', {'in': isProgressVisible}]"
    >
      <div class="spinner"></div>
      <div class="progress">
        <div class="progress-bar progress-bar-success" :style="{ width: progress + '%' }"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { Loader } from "@googlemaps/js-api-loader";

import helpers from "../../helpers.js";

import Filter from "../shared/filter/findings_filter_core";
import InfoBadge from "../shared/info_badge";

export default {
  extends: Filter,

  components: {
    InfoBadge
  },

  props: {
    apiKey: String,
    site: Object,
    siteWorstCaseDamage: Number,
    showLeCondition: Boolean
  },

  data: function() {
    return {
      filter: {},
      assets: [],
      markers: [],
      google: null,
      mapPins: [],
      mapPinRatingIcons: [
        'iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAGDSURBVHgBpVM7bsJAEB0vP5kqAgQSEpJrmrhMGTo6uEJuEApqyAkIx0gHJVVyBEscwCAkhEBAJBCO/3mzIpGDEmQrr/Cud+fzZuatQhcYDAb3QRC0FEVph2Go8Rn2BvaGEOKp0+nMovZKxPEGSw/Gj+VymYrFIuVyOXl3Op3IsixaLpfkOM7zOdD7dwB29n3/tVQq6bVajVKpFP0G2Mggm83GQKIGBxHnu16lUtE1TfvTmcF3nKBQKOgosycZILuWTqfNer1+1fmSyXQ6Jdd1G4IjVavV2M5fTLhPWNsCteiqqlJSoF+EybQEPno+n6ekyGazvGiC/gkuYWbbNiUFa4MFxgzG2+2WkoKFJdWJkYzW67UcTVx4nicFxYoU3W73DQfDxWIRO8BqtSKUPeR3IZuIefZ3u53BQa4x4czz+ZzA2GAf6cufyWTy0Ww2Xw6Hg7rf7++gTOmQyWTkyk2G/sk0TToej0NQf/jxmKJgaYNFH0a3rBFphElBsWP8j7jkqP0nJw7CwryVcikAAAAASUVORK5CYII=',
        'iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAGgSURBVHgBnVO9TsJQFD5tQSAQEEL4CYMdGNwkcWmcID4AvIJv4OhgQtkY5S100yeQwQEJQzfjQKiJkMp/E5rcptziPU1RQoQUv6TtzTnn++7pud/lYAvSrVSklJbZssIe0Q0rwIEi2EKtVW+pm/XcelGUi8eGaVR5H38dz8UhlomBP+h3cmRBwFyYMPmYgEWsOytg1RRZmf8IuOTnaDpaSOfTwETgL9hLG8bqGGb9mcJESijiVOLObNdC9jS7k4zAXCqfAtzIb/qrTgfSjSTyIb4nnot7yduddF+7wFlciac8rSZPkp7J604SuQRQoBVkFQKRAByKaCaKEyzzsPqfgHtCove+dwAF1CVZwqFAXzAo+AtPuqbDoUBzoTv5Fawep/2pczRegbXoSrS2MHgZqJmLTNy2bCmSjHgSGPVGYEyMRrvevneGSINU1r90Zdgd7u0Ec9q7BvPPuRIOhmWMCfjSmhpJXaYeyJSEFqOFJPicMPiOfM4Xh8z8D4O3ARCdNBj5qik3fy/TJtDalKMyy5yhR9ywisPGeXXqneZm/Tf3PrlN4mm/4gAAAABJRU5ErkJggg==',
        'iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAGGSURBVHgBpVNdTsJAEJ7dSivwIg3VqqhNNPpk7BHkBMBJlBMAJ0BOgp5AbyBPhgeN9QeClhRfFFq3rDsVTCFK2vglTdud/b6Znf2GwBz26s1jILTAgRfFr4FrHKBFxJPgvNYul6zwfjL9MOrNFUpoJSGRU13TQFNVUOREEPsYjuB9OITnXg9GHjsb83HNKpfefgS+yeRyVc2YO5sbsCRJ8Bt83xciL9C1+60x53kUoRjAzOta1tzd3vqTjJBEDBNgIuQEFYjsRkqR7w8P9heS5yu5vmmDz1ieSoRUcroemTytRNey2NwiFU0w08lliAtNzYj6SYEKFTOVTEJcKLKML4PCP4EClut5EBfoCzQYBc4vbGcAcYHmQndiE897dh+YuJqoYBNDobXpbbl0xRhrPHS6kQU6guy6bgPnImiiyF19dQYtFFlUCcbuHp8CKyMH12aGSVipqijKSU5fg7S42tTEH673CbbjQHBUUS2SZ4YpDLQ2ChFCjtAjk2ULm439wiOH938B19iqAAw9rJcAAAAASUVORK5CYII=',
        'iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAGrSURBVHgBpVM9TwJBEJ1ZwDvkjJQWfmDUwkZJjImlxt6P0sKCf2BpYQJ2lvILLCyMldLZSWdiYnJaSKEEIiRGC4HwcXtyd+vOIoYQJRBfc7c7O29m37xF6MLpgb6KLm56ILbkMkJ7CGgiCtPnweHOEc93nsf2z0UiHK7adjzgx73ZCR0mx3QI6UzFyjUXKjUHMrkGcO4ch7Tg4XaiXP4hoOSabV9Pjg1FF+YMkCTwG5qOUCTZIjcNTVsjElWCKs+M69Gl+ZE/kwkUW5gLARWq21ZcdXC2r0cCQV9ufTncM7m7k6ubEjhNb425DOLz08N9J7c7IZ1k/S0mAKNhwweDYkqKLFBsMiFEdNTww6AYbk0owuCfIIJ8nXswKCrSGwjClFfA1Msrh0FRrjpSAzQZA3H5XLTUaPqFMlS+Aa60Nts94unPJiTvn+p9E5AbLctLxuS7UCJ6upYovNnmgyTp1QnF7jJVZWVH5tCeMkAqzfnGunFe+rCDxXd7JeBvDUfXWl8SOVuw4PaxBqWKm5TJsVjnY+rEibQ2Q0gwxEXyyPd2nsQmvejKnee/AG19wHj+QloaAAAAAElFTkSuQmCC',
        'iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAGESURBVHgBpVNbTsJQEJ1eCgXiR7GmWF+pRj/8skuQFcAS3IHugLoD2YFLgBXgBkwaf/mgkcijguEHtNL2OlOKVqKEhpM0be+9c+6ZmTMCLKF5tXvJgJc5QAWA69GyRQ8Hdlu679vx88JPoC4zmFQFMX2jqBoUlCKkJSnc+5hOwsfpdcBz3bsAckhkj78JKFiAaVPeUQ3t4BiYKMJf8H0PnO4zvA16qCZfIhJGG3SzUtSMff3s32BCKiWCdngCdBGD92qoAHPWM1K6fXpurAxeVtJ6egTPD0pMgKCqakdrBy+UbBf38ItXKAUjm9+CpCgoKhaQlyOCPCRF1CGdwYYgAnvmupAU5AvsgcU48MZ45EBSzAm4hQpS9dGgG7ZmXfieF7qSrM3Q2w+eP6v1O+21CV4x+NN1azQXURFn5njoWESySgnd/GK3ABXjcOVMWosNkyxjc8yMlL0mY5E3Fu2lIlOdhg6m6vk1Cv41THGQtQECEzcu8NeYrwo2jncD33VKOX7+C8VQpheXCmRXAAAAAElFTkSuQmCC',
        'iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAGWSURBVHgBpVNNTsJAFH4zbfkxGiHBRhISm8iClXbpUk4AV+gNvAFwAzkBa3dyAr2BXUJCYhNISrAElEBbSqfOG5QgUULjt2k6877v/X1DYAetUvGWhKwCFKoQgfZ1bAIQM6JKw+h0rO14siFqWoYotCbL0l0+l4OzbBaSiiLuFp4Hc9eF/mgEfrC8j3zWMCxruhEQ5AR9UrNZ/SKfB1mS4DeswhAGXMR2HDNasjKKUKHCM/Os+mWh8CcZgXcaT4CJSJLWBLdV0rSUnHq9Khb3kncreel2IYiiMiWM1gqqejD5u5JzPicIWRVb0I/TaYgLNZMBQqAiBI5SKYiLZCKBH43CP0H5Ii0vCCAu5twbHCaNGLTfJhOIiwU3FrqTgkQfh44jVnMoVowJV6K1qdHpPQersGnZ9sECg+EQPH/ZxHexHmLA6rwNE0X2VYKZe/0+2OOxiRw8E+5pT6de5eT0Yea56fHH+43EjUL4khVZFkSfD5n7X5BnC7fJycaPx7QNtDYwUidArvmvDusoC4eN88KWt+M/Ab7ks1TSSm1FAAAAAElFTkSuQmCC'
      ],
      mapBounds: null,
      mapOptions: {
        panControl: true,
        zoomControl: true,
        mapTypeControl: true,
        streetViewControl: false,
        gestureHandling: "cooperative",
        scaleControl: false,
        rotateControl: true,
        zoom: 14,
        minZoom: 2,
        maxZoom: 20,
        center: { 
          lat: this.site.latitude, 
          lng: this.site.longitude 
        },
        mapId: this.site.id,
      },
      isSpinnerVisible: true,
      isInitialized: false,
      totalAssetsCount: 0,
      maxAssetsReflect: 200,
      isMoreThanMaxAssetsReflect: false,
      isAssetsListMapReflected: false,
      maxAssetsLabel: 100,
      isMoreThanMaxAssetsLabel: false,
      isLabelPinsOn: false,
      tmpAssets: [],
      isOverlayVisible: false,
      isProgressVisible: false,
      isMapReady: false,
      progress: 0,

      googleMapMaps: null,
      googleMapCore: null,
      googleMapMarker: null
    }
  },

  setup () {
    return {
      h$: helpers
    }
  },

  created: function () {
    let vmFilter = this;

    this.initStateFilter();

    // Listen for the event.
    window.addEventListener("updatestate", (event) => {
      let tmpFilter = vmFilter.getFilterState();

      if (tmpFilter) {
        tmpFilter = tmpFilter.replace("findingDetails", "details");

        tmpFilter = JSON.parse(tmpFilter);

        if (tmpFilter.details) {
          if (tmpFilter.details.constructor === Object && Object.keys(tmpFilter.details).length >= 0) {
            tmpFilter.details = JSON.stringify(tmpFilter.details);
          }
        }

        vmFilter.filter = Object.assign({}, tmpFilter);
      }
    });

    // Handle browser history change
    window.addEventListener("popstate", (event) => {
      const shouldRefresh = (event.state || event.isTrusted) && event.state === null;

      if (shouldRefresh && !this.isAssetsListMapReflected) {
        this.refresh();
      }
    });
  },

  async mounted() {
    const loader = new Loader({
      apiKey: this.apiKey
    });

    this.googleMapCore = await loader.importLibrary("core");
    this.googleMapMarker = await loader.importLibrary("marker");
    this.googleMapMaps = await loader.importLibrary("maps");

    this.google = this.googleMapMaps;

    this.initializeMap();
  },

  watch: {
    filter: function() {
      if (this.google && !this.isAssetsListMapReflected) {
        this.initializeMap();

        this.refresh();
      }
    },

    google: function() {
      if (!this.isAssetsListMapReflected) {
        this.refresh();
      }
    }
  },

  computed: {
    filterQueryParams: function() {
      return (!this.isFilterEmpty) ? "?v-filter=" + JSON.stringify(this.filter) : "";
    },

    isFilterEmpty: function() {
      return (Object.keys(this.filter).filter(x => !["siteId", "historical", "status"].includes(x))).length === 0 && this.filter.historical == false;
    },

    isFilterInHistoricalMode: function() {
      return this.filter && this.filter.historical && this.filter.historical === true;
    },

    isFilterInPrevievMode: function() {
      return this.filter.status ? ["approved", "draft", "review"].every((val) => this.filter.status.includes(val)) : false;
    },

    isProjectFilter: function () {
      return (this.filter.projectId && this.filter.projectId.length == 1);
    },

    filterParams: function () {
      let params = {
        siteId: this.site.id
      };

      if (!this.isFilterEmpty || this.isFilterInHistoricalMode || this.isFilterInPrevievMode) {
        params.findings = this.filter;

        params.findings.siteId = this.site.id;
        params.findings.historical = this.filter.historical || false;
      };

      return params;
    },

    queryName: function () {
      if (this.isProjectFilter) {
        return "grid.site.projectAssets";
      } else {
        return "grid.site.assets";
      }
    }
  },

  methods: {
    damageRating: function(asset) {
      return this.isFilterInPrevievMode ? asset.latestSummary.remediationPreviewRating : asset.latestSummary.clientRemediatedRating
    },

    refresh: function() {
      this.clearMap();

      this.fetchAssets();
    },

    setAssetsListMapReflected: function($event) {
      this.isAssetsListMapReflected = !this.isAssetsListMapReflected;

      this.initializeMap();

      if (this.isAssetsListMapReflected) {        
        this.setAssets(vmSitePhysicalAssets.$refs.sitePhysicalAssets.items);
      }
      else {
        this.fetchAssets();
      }
    },

    setLabelPins: function($event) {
      this.isLabelPinsOn = !this.isLabelPinsOn;

      this.initializeMap();

      this.addAssetsOnMap();
    },

    setAssets: function(assets) {
      this.tmpAssets = assets;

      if (this.isAssetsListMapReflected) {
        this.assets = assets;

        this.addAssetsOnMap()
      }
    },

    initializeMap: function() {
      this.mapOptions.mapTypeControlOptions = {
        style: this.googleMapMaps.MapTypeControlStyle.HORIZONTAL_BAR,
        position: this.googleMapCore.ControlPosition.TOP_RIGHT
      };

      this.mapOptions.mapTypeId = this.googleMapMaps.MapTypeId.HYBRID;

      this.mapOptions.zoomControlOptions = {
        position: this.googleMapCore.ControlPosition.RIGHT_BOTTOM
      };

      // Draw map
      const mapContainer = this.$refs.map;

      this.map = new this.googleMapMaps.Map(mapContainer, this.mapOptions);

      // Hide spinner on map load
      this.map.addListener("tilesloaded", () => {
        this.isSpinnerVisible = false;
      });

      this.map.addListener("center_changed", () => {
        window.setTimeout(() => {
          // to prevent flickering after first zoom pins are ready to be shown
          this.isMapReady = true;
        }, 1000);
      });
    },

    clearMap: function() {
      this.mapBounds = new this.googleMapCore.LatLngBounds();

      this.mapPins.forEach((pin) => {
        pin.setMap(null);

        pin.visible = false;
      });

      if (this.mapClusterer) {
        this.mapClusterer.clearMarkers();
      }

      this.mapPins = [];
      this.markers = [];
    },

    addSiteOnMap: function() {
      const vmMap = this;

      const mark = document.createElement("div");

      mark.className = "map-info-marker rating-" + this.siteWorstCaseDamage;
      mark.innerHTML = "<div>" + this.site.name + "</div>" + (this.site.description ? "<div class='note'>" + this.site.description + "</div>" : "");

      const marker = new this.googleMapMarker.AdvancedMarkerElement({
        map: vmMap.map,
        position: { lat: vmMap.site.latitude, lng: vmMap.site.longitude },
        content: mark,
      });

      this.mapPins.push(marker);

      this.mapBounds.extend(new this.googleMapCore.LatLng(vmMap.site.latitude, vmMap.site.longitude));

      this.map.setZoom(15);
    },

    labelMarker: function(label, color) {
      const canvas = document.getElementById("tmpLabel");
      const ctx = canvas.getContext("2d");

      // Set text properties
      const borderColor = this.h$.colorShade(color, -60);
      const fontSize = 20;
      const fontFamily = "Arial";
      const radius = 6;

      // Set label dimensions
      const labelWidth = ctx.measureText(label).width + 28; // Add padding
      const labelHeight = 34;
      const labelX = 0; // X position of the label
      const labelY = (canvas.height - labelHeight) / 2; // Center vertically

      canvas.width = labelWidth;

      // Clear canvas first
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      
      // Draw rounded rectangle background
      ctx.fillStyle = color;
      
      ctx.beginPath();
      ctx.moveTo(labelX + radius, labelY);
      ctx.lineTo(labelX + labelWidth - radius, labelY);
      ctx.quadraticCurveTo(labelX + labelWidth, labelY, labelX + labelWidth, labelY + radius);
      ctx.lineTo(labelX + labelWidth, labelY + labelHeight - radius);
      ctx.quadraticCurveTo(labelX + labelWidth, labelY + labelHeight, labelX + labelWidth - radius, labelY + labelHeight);
      ctx.lineTo(labelX + radius, labelY + labelHeight);
      ctx.quadraticCurveTo(labelX, labelY + labelHeight, labelX, labelY + labelHeight - radius);
      ctx.lineTo(labelX, labelY + radius);
      ctx.quadraticCurveTo(labelX, labelY, labelX + radius, labelY);
      ctx.closePath();
      
      ctx.fill();
  
      ctx.strokeStyle = borderColor;
      ctx.lineWidth = 1;
      ctx.stroke();

      // Set text properties
      ctx.font = fontSize + "px " + fontFamily;
      ctx.fillStyle = "#ffffff";

      // Draw text
      ctx.fillText(label, labelX + 14, labelY + fontSize + 4);

      return {
        url: canvas.toDataURL("image/png"),
        width: canvas.width / 2
      }
    },

    addAssetsOnMap: function() {
      const vmMap = this;

      let infowindow = new this.googleMapMaps.InfoWindow();

      // Check if ctrl or alt key is pressed in combination with mouse event
      let isCtrlALt = function(e) { 
        for(var i in e) {
          if(e[i] instanceof window.MouseEvent) {
            return e[i]["ctrlKey"] || e[i]["altKey"];
          }
        }
      }

      // remove all map pins
      this.clearMap();

      // prepare markers
      this.assets.forEach((asset) => {
        let marker = [];

        marker.push(asset.name);
        marker.push("/physical_assets/" + asset.id + this.filterQueryParams);
        marker.push(asset.latitude);
        marker.push(asset.longitude);
        marker.push(this.damageRating(asset));
        marker.push(asset.id);

        this.markers.push(marker);
      });
    
      // Add markers on map
      if (this.markers.length > 0) {
        this.markers.forEach((asset, i) => {
          let icon = {};

          if (this.isLabelPinsOn) {
            const label = this.labelMarker(asset[0], this.h$.damageColors[asset[4]]);

            icon.url = label.url;

            // Label icon should be resized/down-scaled for retina displays
            icon.scaledSize = new this.googleMapCore.Size(label.width, 18);
          }
          else {
            icon = 'data:image/png;base64,' + this.mapPinRatingIcons[asset[4]]
          }

          const marker = new this.googleMapMarker.Marker({
            position: { lat: asset[2], lng: asset[3] },
            map: vmMap.map,
            icon: icon,
            title: 'Ctrl/Alt+Click for Asset page'
          });

          marker.physicalAsset = {
            id: asset[5],
            url: asset[1],
            rating: asset[4]
          }

          this.mapPins.push(marker);

          const markerTooltip = '<div class="map-marker-tooltip rating-' + asset[4] + '">' + asset[0] + '</div>'

          marker.addListener('mouseover', function() {
            infowindow.open(vmMap.map, marker);
            infowindow.setContent(markerTooltip);
          });

          marker.addListener('mouseout', function() {
            infowindow.close();
          });

          marker.addListener("click", (e) => {
            if (isCtrlALt(e)) {
              // Go to marker item page
              window.location.href = marker.physicalAsset.url;
            }
            else {
              // Show marker item details in left sidebar details pane
              this.showAssetDetails(marker.physicalAsset.id);
            }
          });

          this.mapBounds.extend(new this.googleMapCore.LatLng(asset[2], asset[3]));
        })
      
        this.map.fitBounds(this.mapBounds);
      }
      else {
        this.addSiteOnMap();
      }

      this.isInitialized = true;

      this.map.setCenter(this.mapBounds.getCenter());
    },

    showAssetDetails: function(assetId) {
      vmDetailsPane.$refs.detailsPane.open({
        title: "Physical Asset Details",
        entity: assetId,
        entityType: "physicalAssetDetails",
        trackHistory: false,
        args: null
      });

      app.ui.grid.markRow({
        grid: "sitePhysicalAssets",
        row: assetId
      });
    },

    queryParams: function (params) {
      if (this.isProjectFilter) {
        params.projectId = this.filter.projectId[0];
      }

      return Object.assign({
        preview: this.isFilterInPrevievMode,
        includeLECondition: this.showLeCondition,
      }, params);
    },

    fetchAssets: function() {
      const vmMap = this;

      let fetchParams = {
        pageSize: 200,
        filter: {
          siteId: this.filterParams.siteId,
          findings: this.filterParams.findings
        }
      };

      let currentPage = 1;

      this.assets = [];

      let fetch = (cursor, success) => {
        fetchParams.cursor = cursor;

        app.graphql.get(this.queryName,
          this.queryParams(fetchParams),

          (data) => {
            let assets = data.data.physicalAssets,
                pageInfo = assets.pageInfo;

            success(assets, pageInfo);
          },

          (error) => {
            app.ui.toast.add({
              priority: "danger",
              title: "Something went wrong!",
              message: "Unable to fetch site assets!"
            });
          }
        )
      };

      let getAssets = (cursor) => {
        fetch(
          cursor,

          (assets, pageInfo) => {
            this.totalAssetsCount = assets.totalCount;
            this.isMoreThanMaxAssetsReflect = this.totalAssetsCount > this.maxAssetsReflect;
            this.isMoreThanMaxAssetsLabel = this.totalAssetsCount > this.maxAssetsLabel;

            if (!this.isInitialized) {
              this.isAssetsListMapReflected = this.isMoreThanMaxAssetsReflect;
              this.isLabelPinsOn = !this.isMoreThanMaxAssetsLabel;
            }
            
            if (assets.totalCount > 0) {
              if (!this.isMoreThanMaxAssetsReflect || this.isInitialized) {
                if (assets.totalPageCount > 1) {
                  this.isOverlayVisible = true;
                  this.isProgressVisible = true;
                  this.progress = currentPage / assets.totalPageCount * 100;

                  currentPage++;
                }

                assets.edges.forEach((edge) => this.assets.push(edge.node));
              
                if (pageInfo.hasNextPage && pageInfo.endCursor) {
                  Promise.resolve(getAssets(pageInfo.endCursor));
                }
                else {
                  this.addAssetsOnMap();

                  this.isProgressVisible = false;

                  const snooze = window.setTimeout(function () {
                    vmMap.isOverlayVisible = false;
                    vmMap.progress = 0;

                    window.clearTimeout(snooze);
                  }, 1000);
                }
              }
              else {
                if (this.tmpAssets.length > 0) {
                  this.assets = this.tmpAssets;

                  this.addAssetsOnMap()
                }
              }
            }
            else {
              this.addSiteOnMap();
            }
          }
        );
      };

      getAssets(window.btoa(0));
    }
  }
}
</script>
